<script setup>
const { t } = useI18n();

const emit = defineEmits(['item:clicked']);

function goToProfile() {
    emit('item:clicked', { close: true });
}

const navigationMobile = [
    {
        label: t('pages.dashboard'),
        link: '/members/dashboard',
    },
    {
        label: t('global.calendar'),
        link: '/members/calendar',
    },
    {
        label: t('global.archive'),
        link: '/members/archiv',
    },
    {
        label: t('global.documents'),
        link: '/members/documents',
    },
];
</script>

<template>
    <!-- TODO check if we can switch to CommonMenu -->
    <div
        class="shadow-xl px-8 pt-6 pb-3 bg-white absolute top-[calc(100%+1rem)] right-0 md:right-[calc(100%-3.75rem)] w-[250px] rounded-lg"
    >
        <ul
            class="flex md:hidden flex-col border-b border-neutral-200 pb-6 mb-6"
        >
            <li
                v-for="(item, index) in navigationMobile"
                :key="index"
                class="mb-2 last:mb-0"
            >
                <NuxtLink
                    class="text-slate-800 hover:text-brand font-bold block"
                    exact-active-class="active text-brand"
                    :to="item.link"
                    @click="goToProfile"
                >
                    {{ $t(item.label) }}
                </NuxtLink>
            </li>
        </ul>
        <ul class="">
            <li class="pb-3">
                <NuxtLink
                    class="text-black flex items-center gap-3 font-bold w-full group"
                    to="/members/profile"
                    @click="goToProfile"
                >
                    <IconUser class="w-5" />
                    <span class="group-hover:text-brand">
                        {{ $t('global.profile') }}
                    </span>
                </NuxtLink>
            </li>
            <li class="pb-3">
                <NuxtLink
                    class="text-black flex items-center gap-3 font-bold w-full group"
                    to="/members/archiv"
                >
                    <IconArchive class="w-5" />
                    <span class="group-hover:text-brand">
                        {{ $t('global.archive') }}
                    </span>
                </NuxtLink>
            </li>
            <li class="pb-3">
                <button
                    class="text-black flex items-center gap-3 font-bold w-full group"
                    @click="logoutUser('/members/login')"
                >
                    <IconLogout class="w-5 h-5" />
                    <span class="group-hover:text-brand">
                        {{ $t('global.logout') }}
                    </span>
                </button>
            </li>
        </ul>
    </div>
</template>
